.dash.light {
    background-color: #fff; /* Light theme background */
    color: #000; /* Light theme text color */
    
  }
  
.dash.dark {
    background-color: #757575; /* Dark theme background */
    color: #fff; /* Dark theme text color */
   
  }
  .customContent {
    position: absolute;
    top: 10%;       /* Start 10% from the top */
    left: 5%;      /* Start 10% from the left */
    right: 5%;     /* End 10% from the right */
    bottom: 5%;    /* End 10% from the bottom */
    border: 0px solid #ccc;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 20px; 
}
.customContent.light {
    background: #fff;
    background-color: #fff; /* Light theme background */
    color: #000; /* Light theme text color */
}

.customContent.dark {
    background: #757575;
    background-color: #757575; /* Dark theme background */
    color: #fff; /* Dark theme text color */
}
.toggle-header-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  color: inherit; /* Inherit color based on theme */
  transition: transform 0.2s ease-in-out; /* Animation for hover effect */
  margin-right: 10px;
}

.toggle-header-button svg {
  width: 20px;
 
  fill: currentColor; /* Use the inherited color */
}

.light.toggle-header-button:hover, .dark.toggle-header-button:hover {
  transform: scale(1.2); /* Scale up on hover */
  color: #ff7c05; /* Hover color */
}

.toggle-header-button:focus {
  outline: none;
}

.light .toggle-header-button {
  color: #000; /* Button color for light theme */
}

.dark .toggle-header-button {
  color: #fff; /* Button color for dark theme */
}
