.coins-wallet.light {
  background-color: #fff; /* Light theme background */
  color: #000; /* Light theme text color */
  border: 2px solid #ccc;
}

.coins-wallet.dark {
  background-color: #333; /* Dark theme background */
  color: #fff; /* Dark theme text color */
  border: 2px solid #666;
}

.coins-wallet {
  padding: 5px;
  margin: 5px 5px 5px 0px;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Additional dark theme styles for table headers */
.coins-wallet.dark th {
  background-color: #555; /* Darker shade for header in dark mode */
  color: #ddd; /* Lighter text for visibility */
}

.wallet {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wallet-balance, .token-accountW {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: var(--tile-bg);
  margin-bottom: 5px;
}
.coin-tile {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: var(--tile-bg);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
body.light .wallet-balance, body.light .token-account {
  --tile-bg: #fff;
}

body.dark .wallet-balance, body.dark .token-account {
  --tile-bg: #333;
}

.right {
  text-align: right;
  float: right;
}

.wallet-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: var(--tile-bg);
}

.wallet-link-wallet {
  color: blue; /* Default link color */
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.light .wallet-link-wallet {
  color: black; /* Light theme color */
}

.dark .wallet-link-wallet {
  color: white; /* Dark theme color */
}

.wallet-balance span {
  flex-grow: 1;
}

.status-dot {
  display: inline-block;
  margin-left: 10px;
}

.status-icon {
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 5px;
}

/* Consistent icon colors irrespective of theme */
.orange .status-icon { fill: orange; }
.green .status-icon { fill: green; }
.red .status-icon { fill: red; }
.wallet-icon {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  fill: currentColor; /* Use the color inherited from the parent */
  transition: transform 0.2s ease-in-out;
}
.wallet-icon:hover {
  transform: scale(1.2); /* Scale up on hover */
  color: #ff7c05!important; /* Hover color */
}
.token-image-container-wallet {
  display: flex;
  align-items: center;
  align-self: center;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 1s ease-in-out;
}