/* Base styles for the settings form */
.settings-form {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    border: 0;
    outline: none;
    padding: 20px;
}

/* Dark theme styles */
.settings-form.dark {
    background: #757575;
    color: #fff;
}

/* Light theme styles */
.settings-form.light {
    background: #fff;
    color: #000;
}

.settings-form h2 {
    margin-top: 0;
}

.settings-form button {
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
}

/* Additional styles for tabs and content */
.tabs {
    display: flex;
    justify-content: start;
    padding: 0 20px;
    background-color: #e0e0e0;
    height: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0;
    z-index: 1000;
    box-sizing: border-box;
}
  
.tabs.dark {
    background-color: #2c2c2c;
}
  
.tabs.light {
    background-color: #e0e0e0;
}
  
.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 3px solid transparent;
}
  
.tab.active {
    border-color: #ff7c05;
}

.settings-section {
    display: none;
    margin-top: 20px;
}

.settings-section.active {
    display: block;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 5px;
}

.input-group-label {
    margin-right: 5px;
    margin-bottom: 20px;
}
.input-group-row{
   
    margin-bottom: 20px;
}

.input-group-item {
    margin-right: 20px;
}

.input-group input {
    width: 100px;
    margin-right: 2px;
    font-size: 16px;
    height: 30px;
}

.input-group select {
    width: 140px;
    margin-right: 2px;
    font-size: 16px;
    height: 30px;
}

/* Dark theme styles for input and select */
.settings-form.dark .input-group input,
.settings-form.dark .input-group select {
    background-color: #757575;
    color: #fff;
    border: 1px solid #ccc;
}

/* Light theme styles for input and select */
.settings-form.light .input-group input,
.settings-form.light .input-group select {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
}

/* Dark theme styles for input and select */
.settings-form.dark .input-groupCopy select {
    background-color: #757575;
    color: #fff;
    border: 1px solid #ccc;
    width: 310px;
    margin-right: 2px;
    font-size: 16px;
    height: 30px;
    margin-bottom: 20px;
    margin-top: 5px;
    cursor: pointer;
}

/* Light theme styles for input and select */
.settings-form.light .input-groupCopy select {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    width: 310px;
    margin-right: 2px;
    font-size: 16px;
    height: 30px;
    margin-bottom: 20px;
    margin-top: 5px;
    cursor: pointer;
}

.input-group span {
    margin-left: 5px;
    font-size: 16px;
}

/* Switch styles */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.icon2 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 10px;
}

.icon2.fast {
    color: #2196F3;
}

.icon2.secure {
    color: #ccc;
}

input:checked ~ .icon2.fast {
    color: #ccc;
}

input:checked ~ .icon2.secure {
    color: #2196F3;
}

.button-container {
    display: flex;
    margin-top: 10px;
}

.small-button {
    padding: 5px 10px !important;
    font-size: 14px;
    margin: 5px;
}

.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
}


.input-groupPB-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 5px;
    gap: 10px;
  }
  
  .input-groupPB {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
  
  .input-containerPB {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    width: 120px; /* Adjust width as needed */
    height: 80px; /* Ensure all elements have the same height */
    box-sizing: border-box;
    border-radius: 5px;
  }
  .input-containerPB.dark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #757575;
    cursor: pointer;
    text-align: center;
    width: 120px; /* Adjust width as needed */
    height: 80px; /* Ensure all elements have the same height */
    box-sizing: border-box;
    border-radius: 5px;
  }
  .input-containerPB.light {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    width: 120px; /* Adjust width as needed */
    height: 80px; /* Ensure all elements have the same height */
    box-sizing: border-box;
    border-radius: 5px;
  }
  
  .input-containerPB.activePB {
    border-color: orange;
  }
  
  .input-fieldPB {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    text-align: center;
    width: 100%; /* Full width of the container */
    box-sizing: border-box;
  }
  .input-fieldPB.dark {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    text-align: center;
    width: 100%; /* Full width of the container */
    box-sizing: border-box;
    color: white
  }
  .input-fieldPB.dark::placeholder {
    color: white;
  }
  .input-fieldPB.light {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    text-align: center;
    width: 100%; /* Full width of the container */
    box-sizing: border-box;
  }
  
  .input-fieldPB:focus {
    outline: none;
  }
  
  .multiplierPB {
    font-weight: bold;
  }
  
  .amountPB {
    margin-top: 5px;
  }
  
  .timePB {
    margin-top: 5px;
    font-size: 0.9em;
    color: gray;
  }
  .timePB.dark {
    margin-top: 5px;
    font-size: 0.9em;
    color: white;
  }
  .timePB.light {
    margin-top: 5px;
    font-size: 0.9em;
    color: gray;
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  .snackbar-container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1400;
  }
  .settings-form.dark .input-groupPB {
    background-color: #757575;
    color: #fff;
  }
  
  .settings-form.light .input-groupPB {
    background-color: #fff;
    color: #000;
  }