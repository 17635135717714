/* Base styles for the settings form */
.wallet-form {
    position: absolute;
    top: 55px;       /* Start 10% from the top */
    left: 0;      /* Start 10% from the left */
    right: 0;     /* End 10% from the right */
    bottom: 0;    /* End 10% from the bottom */
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    border: 0;
    outline: none;
    padding: 20px; 
}

/* Dark theme styles */
.wallet-form.dark {
    background: #757575;
    background-color: #757575; /* Dark theme background */
    color: #fff; /* Dark theme text color */
    
}

/* Light theme styles */
.wallet-form.light {
    background: #fff;
    background-color: #fff; /* Light theme background */
    color: #000; /* Light theme text color */
   
}

.wallet-form h2 {
    margin-top: 0;
}

.wallet-form button {
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
}
.import-section {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .private-key-input {
    margin-bottom: 10px;
    padding: 8px;
    width: 300px;
  }
  .success-message {
    margin-top: 20px;
    text-align: center;
  }
  .wallet-address-container {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .wallet-address {
    color: #666666;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
  }
  .copy-button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .private-key-section {
    margin-top: 20px;
  }
  .private-key {
    font-family: monospace;
  }
  .show-private-key-button {
    margin-top: 10px;
    padding: 8px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .wallet-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wallet-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .private-key {
    padding: 10px; /* Adjust padding as needed */
    border-radius: 8px; /* Rounded corners */
    font-family: monospace; /* Ensures the private key is easy to read */
    font-size: 16px; /* Adjust font size as needed */
    display: block; /* Changed to block to allow width control */
    word-wrap: break-word; /* Ensures text wraps onto multiple lines */
    white-space: pre-wrap; /* Maintains whitespace formatting and wraps text */
    max-width: 500px;
}

/* Dark theme styles */
.dark .private-key {
    background-color: #fff; /* White background for dark theme */
    color: #333; /* Dark text for contrast */
}

/* Light theme styles */
.light .private-key {
    background-color: #757575; /* Dark grey background for light theme */
    color: #fff; /* White text for contrast */
}

/* Base styles for the wallet address container */
.wallet-address {
    padding: 10px; /* Adds padding around the text */
    border-radius: 8px; /* Applies rounded corners */
    font-family: monospace; /* Uses a monospaced font for clearer address display */
    font-size: 16px; /* Sets a standard font size */
    display: inline-block; /* Ensures the background only wraps around the text */
}

/* Dark theme styles */
.dark .wallet-address {
    background-color: #fff; /* White background for dark theme */
    color: #333; /* Dark text for contrast */
}

/* Light theme styles */
.light .wallet-address {
    background-color: #757575; /* Dark grey background for light theme */
    color: #fff; /* White text for contrast */
}
/* CreateWalletForm.css */

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  text-align: center;
}

.confirmation-modal.dark {
  background-color: #333;
  color: #fff;
  border-color: #666;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.confirmation-modal button {
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
    font-size: 15px;
}