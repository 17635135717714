/* Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #f8f9fa; /* Default light background color */
    box-shadow: 2px 0 5px rgba(0,0,0,0.3);
    padding: 10px;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar.light {
    background-color: #f8f9fa; /* Light theme background color */
}

.sidebar.dark {
    background-color: #343a40; /* Dark theme background color */
    color: #ffffff; /* Dark theme text color */
}

.sidebar .switch-container {
    margin-bottom: 15px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0px; 
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 999;
}

.hamburger-menu {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out; /* Animation for hover effect */
}

.hamburger-menu.light {
    color: black; /* Light theme color */
}

.hamburger-menu.dark {
    color: white; /* Dark theme color */
}

.hamburger-menu:hover,.hamburger-menu.dark:hover, .hamburger-menu.light:hover {
    transform: scale(1.2); /* Scale up on hover */
    color: #ff7c05; /* Hover color */
}

.hamburger-menu:focus {
    outline: none;
}

.social-icons {
    width: 15px;
    height: 15px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: inherit; /* Inherit color from the theme */
}