/* src/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 20px;
    height: 50px;
    background-color: #f0f0f0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100vw;
    margin: 0;
    box-sizing: border-box;
}

.header.dark {
    background-color: #333;
    color: #fff;
}

.header.light {
    background-color: #f0f0f0; /* Light theme background */
    color: #000; /* Light theme text color */
}
/* Style for switch */
.switch {
    position: relative;
    display: inline-block;
    width: 50px; /* Adjust the width as needed */
    height: 25px; /* Adjust the height as needed */
    margin-left: 10px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 25px; /* Match height of the slider */
    width: 25px; /* Match height to make it circular */
    left: 0;
    bottom: 0;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 0 1px 0 #ddd;
}

/* Sun and moon icons */
.slider .icon2 {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; /* Adjust size as needed */
}
/*
.slider .sun {
    left: 25%; 
}

.slider .moon {
    right: -25%; 
}
    */

.slider .sun {
    left: 5%; /* Adjust to position the sun icon correctly */
}

.slider .moon {
    right: -40%; /* Adjust to position the moon icon correctly */
}

input:checked + .slider:before {
    transform: translateX(26px);
}

input:checked + .slider {
    background-color: #ff7c05;
}

/* Adjust the color of the sun and moon icons based on your theme */
.header.light .sun { color: black; }
.header.dark .moon { color: white; }

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Style for icons */
.icons {
    width: 22px; /* Adjust as needed */
    height: 22px; /* Adjust as needed */
    fill: currentColor; /* Use the color inherited from the parent */
    margin-left: 10px; /* Adjust spacing as needed */
    transition: transform 0.2s ease-in-out;
}
.settings-link:hover, .logout-link:hover, .wallet-link.dark:hover, .wallet-link.light:hover, .wallet-link-wallet:hover {
    transform: scale(1.2); /* Scale up on hover */
    color: #ff7c05!important; /* Hover color */
  }

/* CSS for the search bar */
.search-bar {
    width: 420px; /* Adjust width as needed */
    height: 25px; /* Adjust height as needed */
    border-radius: 34px; /* Make it round */
    margin-left: 10px; /* Adjust margin as needed */
    padding: 5px 10px; /* Adjust padding as needed */
}

/* CSS for the link to Docs */
.docs-link,
.settings-link,
.logout-link,
.wallet-link {
    color: blue; /* Default link color */
    text-decoration: none;
}

/* Adjust the link color based on theme */
.light .docs-link,
.light .settings-link,
.light .logout-link,
.light .wallet-link {
    color: black; /* Light theme color */
}

.dark .docs-link,
.dark .settings-link,
.dark .logout-link,
.dark .wallet-link {
    color: white; /* Dark theme color */
}

/* CSS for the switch container */
.switch-container {
    display: flex;
    align-items: center;
    margin-right: 15px; /* Adjust margin as needed */
}

/* CSS for the switch names */
.switch-name {
    margin-left: 5px; /* Adjust margin as needed */
    font-size: 14px; /* Adjust font size as needed */
}

/* CSS for the switches when activated */
.switch-container input:checked + .slider {
    /* Background color when the switch is "on" */
    background-color: #ff7c05;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
    .switch-container {
        margin-right: 10px; /* Adjust margin for smaller screens */
    }

    .icons {
        width: 18px; /* Adjust icon size for smaller screens */
        height: 18px;
    }

    .search-bar {
        width: 120px; /* Adjust search bar width for smaller screens */
        margin-left: 5px; /* Adjust margin for smaller screens */
    }

    .switch-name {
        font-size: 12px; /* Adjust font size for smaller screens */
    }
    .header, .tabs {
        padding: 0 10px;  
      }
}
.settings-form {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff; /* default for light theme */
    color: #000; /* default for light theme */
}

/* Dark theme styles */
.settings-form.dark {
    background-color: #757575;
    color: #fff;
    border-color: #222;
}

/* Light theme styles */
.settings-form.light {
    background-color: #fff;
    color: #000;
    border-color: #ccc;
}

.customOverlay {
    position: fixed;
    top: 0; /* Adjusted for your specific needs */
    left: 0;
    right: 16px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25); /* semi-transparent black background */
}

.customContent {
    position: absolute;
    top: 10%;       /* Start 10% from the top */
    left: 5%;      /* Start 10% from the left */
    right: 5%;     /* End 10% from the right */
    bottom: 5%;    /* End 10% from the bottom */
    border: 0px solid #ccc;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 20px;
}

.tabs {
    display: flex;
    justify-content: start;
    padding: 0 20px;
    background-color: #e0e0e0; /* Slightly lighter or darker based on the theme */
    height: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    position: sticky;
    top: 0;
    width: 100%; /* Ensures the header is full width */
    margin: 0;
    z-index: 1000; /* Ensure it is below the header */
    box-sizing: border-box;
  }
  
  .tabs.dark {
    background-color: #2c2c2c; /* Darker background for dark theme */
  }
  
  .tabs.light {
    background-color: #e0e0e0; /* Lighter background for light theme */
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 3px solid transparent; /* Invisible border by default */
  }
  
  .tab.active {
    border-color: #ff7c05; /* Active tab has a blue bottom border */
  }
  
  .trending-tokens {
    margin-left: auto;
    padding: 10px 20px;
    
  }
  
.header .right-section {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.logout-link {
    margin-left: auto; /* This pushes the logout link to the right */
}

.theme-switch-container {
    display: flex;
    align-items: center;
}

.header .control-hidden-in-other-tabs {
    display: none;
}

.header .right-section a,
.header .right-section label {
    margin-left: 10px; /* Ensure consistent spacing between items */
    margin-right: 10px;
}

.logout-link {
    margin-left: 0; /* Remove any specific margin if previously set */
}

/* Adding a background to all tab content to ensure it's visible on scroll */
.tabs {
    background-color: #e0e0e0; /* Adjust based on light/dark theme if necessary */
    box-shadow: 0 0px 4px rgba(0,0,0,0.2);
}

/* Making sure the header does not disappear under any circumstances */
.header {
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow for better visibility over content */
}
* {
    box-sizing: border-box; 
  }
  .language-selector {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
}
.toggle-header-button-header {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: inherit; /* Inherit color based on theme */
    transition: transform 0.2s ease-in-out; /* Animation for hover effect */
}

.toggle-header-button-header svg {
    width: 20px;
    height: 20px;
    fill: currentColor; /* Use the inherited color */
}

.light .toggle-header-button-header:hover, .dark .toggle-header-button-header:hover {
    transform: scale(1.2); /* Scale up on hover */
    color: #ff7c05; /* Hover color */
}

.toggle-header-button-header:focus {
    outline: none;
}

.light .toggle-header-button-header {
    color: #000; /* Button color for light theme */
}

.dark .toggle-header-button-header {
    color: #fff; /* Button color for dark theme */
}
