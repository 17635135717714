.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.supabase-auth-container .my-custom-class {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Resetting margin, padding, and overflow for the entire document */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; 
  overflow: auto; 
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
}

/* This will ensure the entire app container uses the available screen */
.app {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh; /* Ensure it fills the viewport height */
  background-color: #fff; /* light theme */
  color: #000; /* text color for light theme */
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
/* Making sure the header is always at the top */
.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100vw; 
  min-width: 100%;
}

.App-header.dark {
  background-color: #333;
  color: #fff;
}

.App-header.light {
  background-color: #f0f0f0; /* Light theme background */
  color: #000; /* Light theme text color */
}

/* Content area that allows scrolling */
.app-content {
  flex-grow: 1;
  overflow-y: auto; /* Allows scrolling inside this container */
  overflow-x: auto;
}


.app.dark {
  background-color: #757575; /* Dark theme background */
  color: #fff; /* Text color for dark theme */
}

.app.light {
  background-color: #fff; /* Light theme background */
  color: #000; /* Text color for light theme */
}

input[type="checkbox"] {
  accent-color: #ff7c05;
}

.app-content {
  transition: padding-top 0.3s ease-in-out;
}

.app-content.no-header {
  padding-top: 0; /* Padding when the header is hidden */
}