.pinned-tokens.light {
    background-color: #fff; /* Light theme background */
    color: #000; /* Light theme text color */
    border: 2px solid #ccc;
  }
  
  .pinned-tokens.dark {
    background-color: #333; /* Dark theme background */
    color: #fff; /* Dark theme text color */
    border: 2px solid #666;
  }
  
  .pinned-token.dark {
    border: 1px solid #ccc;
  }
  
  .pinned-token.light {
    border: 1px solid #ccc;
  }

.pinned-tokens {
    padding: 5px;
    margin: 10px 5px 5px 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background: var(--tile-bg);
  }
  
  .pinned-container {
    display: flex;
    align-items: center;
    padding: 5px;
    overflow-x: auto;
  }
  
  .pinned-token {
    display: flex;
    align-items: center;
    background-color: var(--tile-bg);
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    max-width: 100%;
    flex-direction: column;
  }
  @media (min-width: 699px) {
    .pinned-token {
      flex-direction: row;
      align-items: center;
    }
    .onlyMob {
        display: none;
      }
      .notMob {
        display: block;
      }
  }
  @media (max-width: 699px) {
    .onlyMob {
      display: block;
    }
    .notMob {
      display: none;
    }      
  }
  
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-row, .middle-row, .bottom-row {
    display: flex;
    align-items: center;
  }
  
  .top-row {
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  
  .middle-row {
    justify-content: space-between;
  }
  
  .middle-row .mint-address {
    margin-left: 10px; /* Align mint address with the name */
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
   .middle-row, .bottom-row {
      flex-direction: column;
      align-items: flex-start;
    }
  }  
  .token-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .token-name {
    font-weight: bold;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  
  .mint-addressP {
    margin-right: 10px;
    /*margin-left: 10px;*/
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  
  .social-linksP {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
  }
  
  .token-link {
    color: inherit;
  }
  
  .social-icons {
    width: 15px;
    height: 15px;
  }
  
  .button-groupP {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-right: 6px;
    justify-content: space-around;
  }
  
  .button-groupP button {
    cursor: pointer;
    font-size: 16px;
    padding: 5px 5px;
    flex: 1 1 calc(50% - 10px);
  }
  
  .unpin-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    margin-left: auto;
  }
  
  .status-container {
    display: flex;
    align-items: center;
  }
  
  .status-dot-pinned {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px; /* Space between dot and message */
  }
  
  .status-dot-pinned.orange {
    background-color: orange;
  }
  
  .status-dot-pinned.green {
    background-color: green;
  }
  
  .status-dot-pinned.red {
    background-color: red;
  }
  
  .status-icon {
    width: 12px;
    height: 12px;
  }
  .status-icon.orange {
    fill: orange; /* Apply color to the SVG */
  }
  
  .status-icon.green {
    fill: green; /* Apply color to the SVG */
  }
  
  .status-icon.red {
    fill: red; /* Apply color to the SVG */
  }
  .status-message {
    font-size: 14px; /* Adjust as needed */
    color: #333;    /* Adjust text color as needed */
  }
  
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }
  @media (min-width: 768px) {
    .button-groupP button {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: space-around;
      flex: 1 1 auto;
      flex-grow: 1;
      flex-shrink: 1;
      
    }
  }

  .button-groupP.button-wide button {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-around;
    flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px);
    padding: 0px
  }

  .button-groupP.button-wide {
    margin-right: 0px;
    width: 100%
  }

  .unpin-button:hover{
    transform: scale(1.2);
  }
  .pinned-token.wide {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    max-width: 283px;
  }
  
  .pinned-token.wide .onlyMob {
    display: block;
  }
  
  .pinned-token.wide .notMob {
    display: none;
  }
  .middle-row.wide, .bottom-row.wide {
    flex-direction: column;
    align-items: flex-start;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate-animation {
    animation: rotate 1s ease-in-out;
  }
  .copied {
    animation: copied-animation 0.5s ease-in-out;
  }
  
  @keyframes copied-animation {
    0% {
      background-color: #e0ffe0;
    }
    100% {
      background-color: transparent;
    }
  }
  
  .token-image-container {
    display: flex;
    align-items: center;
  }
  
  .image-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .network-icon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white; /* Optional: add a background to make it more visible */
    border-radius: 50%; /* Make the icon round */
    padding: 2px; /* Optional: add padding to make space around the icon */
  }
  
  .token-image {
    border-radius: 50%;
    margin-right: 10px;
    align-self: center;
  }
  .limit-order-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.limit-price-container {
    margin-bottom: 16px;
}
.input-group input {
  width: 100px;
  margin-right: 2px;
  font-size: 16px;
  height: 30px;
}