.new-token.light {
  background-color: #fff; /* Light theme background */
  color: #000; /* Light theme text color */
  border: 2px solid #ccc;
}

.new-token.dark {
  background-color: #333; /* Dark theme background */
  color: #fff; /* Dark theme text color */
  border: 2px solid #666;
}

.new-token {
  padding: 5px;
  margin: 5px 5px 5px 0;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Additional dark theme styles for table headers */
.new-token.dark th {
  background-color: #555; /* Darker shade for header in dark mode */
  color: #ddd; /* Lighter text for visibility */
}

.status-icon {
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 5px;
}

/* Adjust the link color based on theme */
.light .token-link {
  color: black; /* Light theme color */
}

.dark .token-link {
  color: white; /* Dark theme color */
}

.status-dot {
  display: inline-block;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.token-account {
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.top-row, .middle-row, .bottom-row {
  display: flex;
  align-items: center;
}

.top-row {
  justify-content: flex-start;
  margin-bottom: 5px;
}

.middle-row {
  justify-content: space-between;
}

.middle-row .mint-address {
  margin-right: 5px;
  white-space: nowrap;
  font-size: 14px;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.right {
  margin-left: auto;
}
/*
.token-link {
  margin-right: 5px;
}*/

.social-icons {
  width: 15px;
  height: 15px;
  transition: transform 0.2s ease-in-out;
}
.social-icons:hover {
  transform: scale(1.2); /* Scale up on hover */
  color: #ff7c05!important; /* Hover color */
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-around;
  padding: 0;
  margin: 5px 0 0 0!important;
}

.button-group button {
  cursor: pointer;
  font-size: 16px;
  flex: 1 1 calc(50% - 10px);
}

/* Light and dark theme adjustments */
body.light .token-tile {
  --tile-bg: #fff;  /* Light background */
  color: #000;      /* Dark text for light theme */
}

body.dark .token-tile {
  --tile-bg: #333;  /* Dark background */
  color: #fff;      /* Light text for dark theme */
}

.new-token, .token-account {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: var(--tile-bg);
  margin: 5px 5px 5px 0;
}

.orange .status-icon { fill: orange; }
.green .status-icon { fill: green; }
.red .status-icon { fill: red; }

.token-tile {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: var(--tile-bg);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-dot {
  display: inline-block;
  margin-left: 10px;
}

.fade-enter {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}
.button-group.button-wide button {
  flex: 1 1 auto;
}

.customOverlay {
  position: fixed;
  top: 0; /* Adjusted for your specific needs */
  left: 0;
  right: 16px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25); /* semi-transparent black background */
}

.mintStr {
  display: flex;
  align-items: 'center';
  justify-content: 'space-between';
}

.top-row.modules-4 {
  max-width: 280px; /* 200px original */
}
.top-row.modules-5 {
  max-width: 155px;
  display: ruby;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 1s ease-in-out;
}
.copied {
  animation: copied-animation 0.5s ease-in-out;
}

@keyframes copied-animation {
  0% {
    background-color: #e0ffe0;
  }
  100% {
    background-color: transparent;
  }
}
.liquidity-icon-border {
  border: 1px solid #cccccc00;
  border-radius: 50%; /* Adjust as needed to match the icon shape */
  padding: 2px; /* Adjust as needed to space out the border */
}
.token-image-container {
  display: flex;
  align-items: center;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.network-icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white; /* Optional: add a background to make it more visible */
  border-radius: 50%; /* Make the icon round */
  padding: 2px; /* Optional: add padding to make space around the icon */
}

.token-image {
  border-radius: 50%;
  margin-right: 10px;
  align-self: center;
}
